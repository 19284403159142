import 'slick-carousel';

(function($){

  function setWinW(){
      return window.innerWidth;
  }

  function sideMenu(){
      $('body').append('<div class="sideNavBg"></div>')
      $('#gheader .gnavBtn, #gsideMenu .menuBtn, .sideNavBg').on('click',function(){
          $('body').toggleClass('sideMenuOpen');
          return false;
      });
  }
  function mainSlides(){
      if($('.m_mvSlides').length){
          $('.m_mvSlides .slides').slick({
              centerMode: true,
              // variableWidth: true,
              centerPadding: '0',
              autoplay: true,
              autoplaySpeed: 4000,
          });
      }
  }

  function tab(){
      $('.js_tab .tabMenu > li a').click(function(){
          var targetTab = $(this).attr('href');
          $('.js_tab .tabMenu > li a').removeClass('is_active');
          $(this).addClass('is_active');
          $(this).closest('.js_tab').find('.tab').hide();
          $(targetTab).fadeIn();
          return false;
      });
  }

  function mediaSlides(){
      var slideW, winW, ctW;
      function slideInit(){
          winW = setWinW();
          ctW = $('#mainContents').width() - 48 - 80;
          if(winW > 1286){
              slideW = ctW / 4.3;
          }else{
              slideW = ctW / 3.2;
          }
      }
      slideInit()
      $('.js_mediaSlides').find('.m_linkCard').css('width', slideW + 'px');

      $('.js_mediaSlides').slick({
          variableWidth: true,
          swipeToSlide: true,
          slidesToShow: 4,
          infinite: false,
          responsive: [{
              breakpoint: 1286,
              settings: {
                  slidesToShow: 3,
              }
          }],
      });

      function slideReInit(){
          slideInit()
          $('.js_mediaSlides').find('.slick-slide').css('width', slideW + 'px');
          $('.js_mediaSlides').slick('setPosition');
      }

      $(window).on('load', function() {
        slideReInit();
      });

      var resizeTimer = null;
      $(window).on('resize', function() {
          slideReInit();
          clearTimeout(resizeTimer);
          resizeTimer = setTimeout(function() {
              slideReInit();
          }, 200);
      });

      $('#gheader .gnavBtn, #gsideMenu .menuBtn, .sideNavBg').on('click',function(){
          $('#mainContents').on("transitionend", function() {
              slideReInit();
          });
      });
  }

  function pickupSlides(){
      $('.js_pickupSlides .slides').slick({
          // variableWidth: true,
          infinite: false,
          swipeToSlide: true,
          slidesToShow: 8,
          responsive: [{
              breakpoint: 1286,
              settings: {
                  slidesToShow: 7,
              }
          }],
      });
      $('#gheader .gnavBtn, #gsideMenu .menuBtn, .sideNavBg').on('click',function(){
          $('#mainContents').on("transitionend", function() {
              $('.js_pickupSlides .slides').slick('setPosition');
          });
      });
  }

  function truncatedText(){
      $('.js_truncated').each(function() {
          var maxLength = $(this).data('maxlength');
          var text = $(this).text();
          if (text.length > maxLength) {
            var truncatedText = text.substring(0, maxLength) + '...';
            $(this).text(truncatedText);
          }
      });
  }

  $(function(){
      sideMenu();
      mainSlides();
      tab();
      mediaSlides();
      pickupSlides();
      truncatedText();
  });

})(jQuery);
